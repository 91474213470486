import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnlockListResponse } from '@sportyano/core/models/unlock-list/unlock-list.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class UnlockListService {

	constructor(private http: HttpClient) { }

	getUnlockPlayerslist(params: any): Observable<UnlockListResponse> {
		return this.http.get<UnlockListResponse>(`${environment.baseUrl}list-players/unlock`, { params: params })
	}

	unlockPlayer(playerId: number) {
		return this.http.post(`${environment.baseUrl}players/${playerId}/unlock`, {})
	}
}
