import { ShortlistService } from '@sportyano/core/services/shortLists/shortlist.service';
import { ShowinterestService } from '@sportyano/core/services/generals/showinterest.service';
import { ShowInterestResponse } from './../../../core/models/interest-list/interest-list.model';
import { AfterViewInit } from '@angular/core';
import { UnlockListService } from '@sportyano/core/services/unlock-list/unlock-list.service';
import { HttpParams } from '@angular/common/http';
import { UnlockListPlayer } from './../../../core/models/unlock-list/unlock-list.model';
import { inject } from '@angular/core';
import { Input } from '@angular/core';
import { Component } from '@angular/core';

enum compType {
	shortList = "shortList",
	interest = "interest",
	unlock = "unlock",
}

@Component({
	selector: 'app-recruitment',
	templateUrl: './recruitment.component.html',
	styleUrl: './recruitment.component.scss'
})
export class RecruitmentComponent implements AfterViewInit {
	@Input() compType: string
	private _unlockListService = inject(UnlockListService)
	private _showInterestService = inject(ShowinterestService);

	private shortListService = inject(ShortlistService);
	playersList: any[];

	filtersParams = new HttpParams();
	unlocakProfile = false
	pageIndex: number = 1
	pageSize: number = 10



	ngAfterViewInit() {
		this.getData()
	}

	getData() {
		if (compType.unlock == this.compType)
			this.getUnlockedPlayersList();
		if (compType.shortList == this.compType)
			this.getShortListedPlayers();
		if (compType.interest == this.compType)
			this.getAllInterests();
	}
	private getAllInterests() {
		this._showInterestService.getPlayersInterests(this.filtersParams).subscribe({
			next: (res: ShowInterestResponse) => {
				this.playersList = []
				this.playersList = res.players
			},
		});
	}

	getShortListedPlayers() {
		this.shortListService.getPlayersShortlist(this.filtersParams).subscribe({
			next: (res) => {
				this.playersList = []
				this.playersList = res.players
			},
		});
	}

	private getUnlockedPlayersList() {
		this._unlockListService.getUnlockPlayerslist(this.filtersParams).subscribe({
			next: (res) => {
				this.playersList = []
				this.playersList = res.players
			}
		})
	}

	changePage(e: any) {
		this.pageIndex = e.pageIndex
		this.pageSize = e.pageSize
	}

	exportFilter(filter: any) {
		this.filtersParams = new HttpParams()
		for (const key in filter) {
			if (filter[key])
				this.filtersParams = this.filtersParams.set(key, filter[key]);
		}
		this.getData()
	}
}
