import { NgModule } from "@angular/core";
import { CheckboxModule } from "primeng/checkbox";
import { MenuModule } from "primeng/menu";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { SliderModule } from "primeng/slider";
import { CarouselModule } from "primeng/carousel";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputNumberModule } from "primeng/inputnumber";
import { PaginatorModule } from "primeng/paginator";
import { ToastModule } from "primeng/toast";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { CalendarModule } from "primeng/calendar";
import { RadioButtonModule } from "primeng/radiobutton";
import { GalleriaModule } from "primeng/galleria";
import { DialogModule } from "primeng/dialog";
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MatDialogModule } from '@angular/material/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from "primeng/tooltip";

const PRIME_NG_MODULES: any = [
	MenuModule,
	CheckboxModule,
	CarouselModule,
	PaginatorModule,
	DropdownModule,
	MultiSelectModule,
	TooltipModule,
	SliderModule,
	InputSwitchModule,
	InputNumberModule,
	CalendarModule,
	RadioButtonModule,
	GalleriaModule,
	DialogModule,
	ToastModule,
	DynamicDialogModule,
	ConfirmPopupModule,
	ConfirmDialogModule,
	MatDialogModule,
	ProgressBarModule,
	
];
@NgModule({
	imports: [PRIME_NG_MODULES],
	exports: [PRIME_NG_MODULES],
})
export class PrimeModule { }
