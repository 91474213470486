import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true
})
export class FileSizePipe implements PipeTransform {

  transform(fileSize: number | undefined, convertTo: 'megaByte' = 'megaByte'): string {
    if (fileSize === null || fileSize === undefined) {
      return '0 MB';
    }

    switch (convertTo) {
      case 'megaByte':
        const mb = fileSize / (1024 * 1024);
        return `${mb.toFixed(1)} MB`;
      default:
        return ''
    }



  }

}
