import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";

const MATERIAL_MODULES: any = [
	MatButtonModule,
	MatCheckboxModule,
	MatIconModule,
	MatRadioModule,
	MatSliderModule,
	MatTooltipModule,
];

@NgModule({
	imports: [MATERIAL_MODULES],
	exports: [MATERIAL_MODULES],
})
export class MaterialImportsModule {}
